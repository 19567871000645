<template>
    <div>
        
        <CRow>
            <CCol lg="12">
                <CButton
                    @click="$router.go(-1)"
                    size="sm"
                    color="warning" 
                > 
                    <font-awesome-icon icon="arrow-left"/> Go back
                </CButton>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol lg="8">
                <CCard :key="update_key">
                    <CCardHeader><font-awesome-icon icon="cog"/>  Manage PrintOut Source
                        <template v-if="data.status == 'Active'">
                            <CButton 
                                size="sm"
                                color="danger" 
                                class="float-right" 
                                @click="toggleEdit(), returnInformation()"
                                v-show="edit"
                            >
                                <font-awesome-icon icon="window-close"/> Cancel
                            </CButton>
                            <CButton 
                                v-if="$store.getters['can']('update-printout-source')"
                                size="sm"
                                :disabled="!isValid"
                                color="success" 
                                class="float-right" 
                                v-show="edit" 
                                @click="updatePrintOutSource()"
                            >
                                <font-awesome-icon icon="save"/> Update
                            </CButton>
                            <CButton 
                                v-if="$store.getters['can']('edit-printout-source')"
                                size="sm"
                                color="info" 
                                class="float-right" 
                                @click="toggleEdit" v-show="!edit"
                            >
                                <font-awesome-icon icon="edit"/> Edit
                            </CButton>
                        </template>
                        <template v-if="data.status == 'Archived'">
                            <CButton 
                                v-if="$store.getters['can']('restore-printout-source')"
                                size="sm"
                                color="success" 
                                class="float-right" 
                                @click="restore()"
                            >
                                <font-awesome-icon icon="trash-restore"/> Restore
                            </CButton>
                        </template>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol lg="8">
                                <CInput 
                                    :disabled="!edit"
                                    :lazy="false"
                                    :value.sync="$v.data.printout_source.$model"
                                    :isValid="checkIfValid($v.data, 'printout_source')"
                                    label="PrintOut Source" 
                                    type="text" 
                                    placeholder="Sample Form" 
                                    autocomplete="off"  
                                    v-model="data.printout_source"
                                    invalidFeedback="Must be more than three(3) characters."
                                />
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        
        <CRow v-show="edit">
            <CCol lg="8">
                <CCard borderColor="danger" v-if="$store.getters['can']('archive-printout-source')">
                    <CCardHeader>
                        <font-awesome-icon icon="exclamation" :style="{ color: 'red' }"/> 
                                Danger Zone
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol lg="8">
                                <h6>Archive this PrinOut Source ?</h6>
                            </CCol>
                            <CCol lg="12">
                                <label> Once archived, you cannot use this source anymore. Please be certain.</label>
                            </CCol>
                        </CRow>
                        <br>
                        <CRow>
                            <CCol lg="3">
                                <CButton shape="pill" color="warning" @click="archive()" > 
                                    <font-awesome-icon icon="minus-square" /> Archived 
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import { required, minLength} from "vuelidate/lib/validators"
export default {
    name:'PrintOutSourceEdit',
    data(){
        return {
            data: {
                printout_source: null,
            },
            update_key: 0,
            data_backup: {},
            disable: 1,
            edit: false,
        }
    },
    computed: {
        isValid () { return !this.$v.data.$invalid },
        isDirty () { return this.$v.data.$anyDirty },
    },
    created() {
        this.getPrintOutSource();
    },
    validations: { // FORM Validation (Vue)
        data: {
            printout_source: {
                required, minLength: minLength(3)
            },
        }
    },
    methods: {
        archive: function () { 
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to archive the ${this.data.printout_source}`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('form-managements/printout-source/archive/' + this.$route.params.id, {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            this.update_key++;
                            this.data.status = 'Archived'
                            this.data_backup.status = 'Archived'
                            this.returnInformation()
                            this.toggleEdit()
                            this.$Progress.finish()
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                timer: 3000,
                                icon: 'success',
                                title: `${this.data.printout_source} successfully archived.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        restore: function() {
            this.$Progress.start()
            axios.post('form-managements/printout-source/restore/' + this.$route.params.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.update_key++;
                    this.data.status = 'Active'
                    this.data_backup.status = 'Active'
                    this.returnInformation()
                    // this.toggleEdit()
                    this.$Progress.finish() 

                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${this.data.printout_source} restored successfully.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                }
            })
        },
        getPrintOutSource: function () {
            axios.get('form-managements/printout-source/' + this.$route.params.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    // this.data = response.data.data
                    this.data.printout_source = response.data.data.source
                    this.data.status = response.data.data.deleted_at ? "Archived" : "Active";
                    this.data_backup = Vue.util.extend({}, this.data)
                }
            })
        },
        returnInformation: function () {
            this.data = Vue.util.extend({}, this.data_backup)
        },
        updatePrintOutSource: function() { 
            if(JSON.stringify(this.data) == JSON.stringify(this.data_backup)) {
               this.$swal({
                    icon: "info",
                    title: "Opps!",
                    text: "No changes has been made.",
                })
                return;
            }
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to update the ${this.data.printout_source}`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('form-managements/printout-source/update/' + this.$route.params.id, this.data, {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                timer: 3000,
                                icon: 'success',
                                title: `${this.data.printout_source} successfully updated.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            this.update_key++;
                            
                            this.data.printout_source = response.data.data.source
                            this.data.status = response.data.data.deleted_at ? "Archived" : "Active";
                            this.data_backup = Vue.util.extend({}, this.data)

                            this.returnInformation()
                            this.toggleEdit()
                            this.$Progress.finish()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        toggleEdit: function() {
            this.edit = !this.edit
            this.disable = (this.disable + 1) % 2
            if(!this.disable) { 
                this.$v.data.$touch()
            }
            else {
                this.$v.data.$reset()
            }
        },
    }
}
</script>

<style>

</style>